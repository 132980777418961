<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="8" :md="8" :xl="6" style="max-width: 200px; margin-bottom: 12px;">
        <a-input-search v-model="searchForm.search" placeholder="单号" allowClear @search="search" />
      </a-col>
      <a-col :span="8" :md="8" :xl="6" style="max-width: 200px; margin-bottom: 12px;">
        <a-input-search v-model="searchForm.user_name" placeholder="用户名" allowClear @search="search" />
      </a-col>
      <a-col :span="8" style="max-width: 200px; margin-bottom: 12px;">
          <a-select v-model="searchForm.is_outline_order" placeholder="线上或线下" allowClear style="width: 100%;" @change="search">
            <a-select-option :value="1">线下</a-select-option>
            <a-select-option :value="0">线上</a-select-option>
          </a-select>
        </a-col>

      <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="onChangePicker" style="width: 100%;" />
          </a-form-model-item>
        </a-col>


      <!--<a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-select v-model="searchForm.is_arrears" placeholder="收款状态" allowClear style="width: 100%;" @change="search">
            <a-select-option :value="true">有欠款</a-select-option>
            <a-select-option :value="false">完成</a-select-option>
          </a-select>
        </a-col>-->

      <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
        <a-range-picker @change="onChangePicker" />
      </a-col> -->
    </a-row>

    <a-row style="margin-top: 12px;">
      <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
        @change="tableChange">
        <div slot="action" slot-scope="value, item">
          <a-button-group size="small">
            <a-button size="small" @click="detial(item)">详情</a-button>
            <a-popconfirm v-if="item.is_completed" title="确定作废吗?" @confirm="voidItem(item)">
              <a-button type="danger" :disabled="item.is_void">{{ item.is_void ? '已作废' : '作废'}}</a-button>
            </a-popconfirm>
          </a-button-group>
        </div>
      </a-table>
    </a-row>
  </div>
</template>

<script>
  import { stockOutRecordsList, stockOutOrdersVoid } from '@/api/warehouse'

  export default {
    name: 'SaleRecord',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '单号',
            dataIndex: 'stock_out_order_number',
            sorter: true,
          },
          {
            title: '用户',
            dataIndex: 'user_name',
          },
          {
            title: '出库产品',
            customRender: (value, item, index) => {
              let products = "";
              for (let i in item.stock_out_record_goods_items){
                  products = products + item.stock_out_record_goods_items[i].goods_name + ','
              }
              return products.substring(0,20)
            },

          },
          {
            title: '经手人',
            dataIndex: 'handler_name',
          },
          {
            title: '配送员',
            dataIndex: 'deliveryman',
          },
          {
            title: '经手日期',
            dataIndex: 'handle_time',
            width: 140
          },
          {
            title: '出库时间',
            dataIndex: 'create_time',
            width: 170
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 147
          },
        ],
        searchForm: { page: 1, is_completed: false, pageSize: 20, user_name:'' },
        pagination: { current: 1, total: 0, pageSize: 20 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
      };
    },
    computed: {
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        stockOutRecordsList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      onChangePicker(date, dateString) {
        let startDate = date[0], endDate = date[1];
        this.searchForm.start_date = startDate ? startDate.format('YYYY-MM-DD') : undefined;
        this.searchForm.end_date = endDate ? endDate.add(1, 'days').format('YYYY-MM-DD') : undefined;
        this.search();
      },

      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      toStockIn(item) {
        this.$router.push({ path: '/warehouse/outStock_create', query: { id: item.id } });
      },
      detial(item) {
        let pi = {"pagename": "outStockRecord", "searchForm": this.searchForm, "pagination": this.pagination}
        this.$store.commit('setPageInfo', pi);
        this.$router.push({ path: '/warehouse/outStock_record_detail', query: { id: item.id } });
      },
      voidItem(item) {
        stockOutOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {
      if (this.$store.state.pageinfo.effect && this.$store.state.pageinfo.pagename == "outStockRecord") {
          this.searchForm = this.$store.state.pageinfo.searchForm;
          this.pagination = this.$store.state.pageinfo.pagination;
          this.$store.commit('setEffect', false);
      }else {
          this.$store.commit('setEffect', false);
      }
      this.initialize();
    },
  }
</script>